.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  /* cursor: pointer; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #001529; */
  /* background-color: #282c34; */
  /* background-color: #00152D; */
  /* background-color: #1f1f1f; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

/* .layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected { */
.layout-topnav .ant-menu .ant-menu-item-selected, .layout-topnav .ant-menu-submenu-popup .ant-menu-item-selected {
  background-color: unset;
  color: unset;
}

.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    border-bottom: 2px solid #1765ad;
}


.signal-stream h4.ant-list-item-meta-title {
  margin-top: 0;
}

.store-cart-list h4.ant-list-item-meta-title {
  margin-top: 0;
}

.account-feature-flag-list h4.ant-list-item-meta-title {
  margin-top: 0;
}


.App-content {
  /* background-color: #001529; */
  /* background-color: #282c34; */
  /* background-color: #00152D; */
  /* background-color: #1d1d1d; */
  /* background-color: #1f1f1f; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.base-theme-light .App-content {
  /* background-color: #001529; */
  /* background-color: #282c34; */
  /* background-color: #00152D; */
  /* background-color: #1d1d1d; */
  /* background-color: #1f1f1f; */
  background-color: #fdfdfd;
}

/* .signal-stream .ant-list-item {
  cursor: pointer
} */




.layout-sidebar .App-content {
  padding: 0px 16px;
}

.layout-topnav .App-content {
  padding: 0px 8px 50px;
}

.layout-topnav .ant-layout-header {
  padding-inline: 10px;
}

@media only screen and (min-width: 768px) {
  .layout-topnav .App-content {
    padding: 0px 50px 50px;
  }

  .layout-topnav .ant-layout-header {
    padding-inline: 50px;
  }
}

.layout-topnav .App-content {
  /* padding: 0px 50px 50px; */
  min-height: 75vh;
}

.layout-topnav.base-theme-light {
  /* background: #f0f0f0; */
  /* color: #0f0f0f; */
}

.layout-topnav .App-page-content {
  min-height: 60vh;
}

.layout-topnav .ant-layout-header {
  /* line-height: 3.8em; */
}

.layout-topnav .ant-layout-header .ant-menu-item{
  /* line-height: 3.8em; */
  height: 64px;
}

.footer-section {
  margin-bottom: 36px;
}

.ant-btn {
  box-shadow: none;
}



/*--- base-theme-dark --- */

.base-theme-dark .signal-stream a {
  color: rgba(255, 255, 255, 0.85);
}

.base-theme-dark .signal-stream .ant-list-item a:hover {
  /* color: #eee; */
  text-decoration: underline;
}


.base-theme-dark .footer-section a:hover {
  color: #e5e5e5;
}

.base-theme-dark.layout-topnav .ant-layout-header {
  /* background-color: #000; */
  /* color: white; */
}


/* .base-theme-dark.layout-topnav .ant-layout-header .ant-menu-light {
  background-color: #000;
  color: white;
}

.base-theme-dark.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #000;
  color: white;
} */

.base-theme-dark.layout-topnav .ant-layout-header .ant-menu-dark, .base-theme-dark.layout-topnav .ant-layout-header .ant-menu-light {
  /* background-color: #000; */
  background-color: unset;
  color: white;
}



.base-theme-dark .footer-section a {
  color: #b0b0b0;
}

.base-theme-dark .App-page-content li {
  color: rgba(255, 255, 255, 0.85);
}

.base-theme-dark .App-page-content pre {
  color: rgba(255, 255, 255, 0.85);
}




/*--- base-theme-light --- */


.base-theme-light.layout-topnav .ant-layout-header .ant-menu-light {
  color: #020202;
}

.base-theme-light.layout-topnav .ant-layout-header .ant-menu-dark {
  color: #020202;
}

.base-theme-light .footer-section a {
  color: #5b5b5b;
}

.base-theme-light .footer-section a:hover {
  color: #2d2d2d;
}
/* --- Dark --- */

.theme-dark.layout-topnav .ant-layout-header,
.theme-dark.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-dark.layout-topnav .ant-layout-header .ant-menu-light,
.ant-menu-submenu .ant-menu-submenu-popup .ant-menu-dark .ant-menu,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.theme-dark .ant-drawer .ant-drawer-content.drawer-nav, .theme-dark .ant-menu-submenu, .theme-dark .ant-menu-submenu .ant-menu.ant-menu-sub,
.theme-dark .ant-layout-footer{
  background-color: #000;
  /* color: white; */
}

/*--- Light --- */

.theme-light.layout-topnav .ant-layout-header {
  background-color: #f5f5f5;
}

.theme-light.layout-topnav .ant-layout-header .ant-menu-light {
  background-color: #f5f5f5;
}

.theme-light.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #f5f5f5;
}




/* --- Pro --- */
.theme-pro.layout-topnav .ant-layout-header, .theme-pro.layout-topnav .ant-layout-header .ant-menu-light, .theme-pro.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #002245;
  color: white;
}

.theme-pro.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  color: white;
}

.theme-pro.drawer-nav.ant-drawer-content {
  /* background: #002245; */
  color: #222;

}

/* --- Purple --- */
.theme-purple.layout-topnav .ant-layout-header, .theme-purple.layout-topnav .ant-layout-header .ant-menu-light, .theme-purple.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #3a2673;
  color: white;
}

.theme-purple.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  color: white;
}

.theme-purple .ant-layout-footer {
  background-color: #f0eef9;
}


/* --- Green --- */
.theme-green.layout-topnav .ant-layout-header, .theme-green.layout-topnav .ant-layout-header .ant-menu-light, .theme-green.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #0e390e;
  color: white;
}

.theme-green.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  color: white;
}

.theme-green.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-green.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #1d761d;
}

.theme-green .ant-layout-footer {
  background-color: #e8f1e8;
}

/* --- catppuccin-mocha --- */
.theme-catppuccin-mocha.ant-layout {
  /* background-color: #313244; */
  /* color: #cdd6f4; */
}

.theme-catppuccin-mocha.layout-topnav .ant-layout-header, .theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-menu-light, .theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-menu-dark {
  /* background-color: #11111b; */
  /* background-color: unset; */
  /* color: #cdd6f4; */
}

.theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  /* color: #cdd6f4; */
}

.theme-catppuccin-mocha.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-catppuccin-mocha.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #89b4fa;
}

.theme-catppuccin-mocha .ant-layout-footer {
  background-color: #181825;
  /* background-color: unset; */
}

.theme-catppuccin-mocha.layout-topnav .ant-layout-header,
.theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-catppuccin-mocha.layout-topnav .ant-layout-header .ant-menu-light,
.theme-catppuccin-mocha .ant-drawer .ant-drawer-content.drawer-nav, .theme-catppuccin-mocha .ant-menu-submenu, .theme-catppuccin-mocha .ant-menu-submenu .ant-menu.ant-menu-sub {
  /* background-color: #000; */
  /* background-color: unset; */
  /* color: white; */
}

/* --- catppuccin-latte --- */

.theme-catppuccin-latte.layout-topnav .ant-layout-header .ant-btn-text, .theme-catppuccin-latte.layout-topnav .ant-layout-header, .theme-catppuccin-latte.layout-topnav .ant-layout-header .ant-menu-light, .theme-catppuccin-latte.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #001529;

  color: white;
}

.theme-catppuccin-latte.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-catppuccin-latte.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #89b4fa;
}

/* --- alt-light --- */

.theme-alt-light.layout-topnav .ant-layout-header .ant-btn-text, .theme-alt-light.layout-topnav .ant-layout-header, .theme-alt-light.layout-topnav .ant-layout-header .ant-menu-light, .theme-alt-light.layout-topnav .ant-layout-header .ant-menu-dark {
  /* background-color: #0e390e; */
  color: white;
}

.theme-alt-light.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-alt-light.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #4285f4;
}

/* --- alt-dark --- */

.theme-alt-dark.layout-topnav .ant-layout-header,
.theme-alt-dark.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-alt-dark.layout-topnav .ant-layout-header .ant-menu-ligh,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark,
.theme-alt-dark .ant-drawer .ant-drawer-content.drawer-nav, .theme-alt-dark .ant-menu-submenu, .theme-alt-dark .ant-menu-submenu .ant-menu.ant-menu-sub {
  background-color: unset;
  }


.theme-alt-dark.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-alt-dark.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #1A73E8;
}

.theme-alt-dark .ant-layout-footer {
  background-color: #161718;
}


/* --- dracula --- */

.theme-dracula.layout-topnav .ant-layout-header,
.theme-dracula.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-dracula.layout-topnav .ant-layout-header .ant-menu-ligh,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark,
.theme-dracula .ant-drawer .ant-drawer-content.drawer-nav, .theme-dracula .ant-menu-submenu, .theme-dracula .ant-menu-submenu .ant-menu.ant-menu-sub {
  /* background-color: #000; */
  background-color: unset;
  
  /* color: white; */
}

.theme-dracula.ant-layout {
  /* background-color: #282a36; */
  /* color: #cdd6f4; */
}

.theme-dracula.layout-topnav .ant-layout-header, .theme-dracula.layout-topnav .ant-layout-header .ant-menu-light, .theme-dracula.layout-topnav .ant-layout-header .ant-menu-dark {
  /* background-color: #11111b; */
  /* background-color: unset; */
  /* color: #cdd6f4; */
}

.theme-dracula.layout-topnav .ant-layout-header .ant-space-horizontal .ant-btn-text {
  /* color: #cdd6f4; */
}

.theme-dracula.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-dracula.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #8be9fd;
}

.theme-dracula .ant-layout-footer {
  background-color: #181825;
  /* background-color: unset; */
}

/*--- nord-light --- */

.theme-nord-light.layout-topnav .ant-layout-header, .theme-nord-light.layout-topnav .ant-layout-header .ant-menu-light, .theme-nord-light.layout-topnav .ant-layout-header .ant-menu-dark {
  background-color: #eceff4;
}

.theme-nord-light.layout-topnav .ant-layout-header,
.theme-nord-light.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-nord-light.layout-topnav .ant-layout-header .ant-menu-light,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark,
.theme-nord-light .ant-drawer .ant-drawer-content.drawer-nav, .theme-nord-light .ant-menu-submenu, .theme-nord-light .ant-menu-submenu .ant-menu.ant-menu-sub {
  background-color: #eceff4;
}


/* -- Nord Dark --- */
.theme-nord-dark.layout-topnav .ant-layout-header,
.theme-nord-dark.layout-topnav .ant-layout-header .ant-menu-dark,
.theme-nord-dark.layout-topnav .ant-layout-header .ant-menu-ligh,
.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark,
.theme-nord-dark .ant-drawer .ant-drawer-content.drawer-nav, .theme-nord-dark .ant-menu-submenu, .theme-nord-dark .ant-menu-submenu .ant-menu.ant-menu-sub {
  background-color: #232831;
}


.theme-nord-dark.layout-topnav .ant-menu.ant-menu-dark .ant-menu-item-selected, .theme-nord-dark.layout-topnav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  border-bottom: 2px solid #8fbcbb;
}

.theme-nord-dark .ant-layout-footer {
  background-color: #232831;
}

/* --- */



.layout-topnav .ant-layout-header .ant-menu-horizontal {
  border: 0;
}



/* .ant-drawer .ant-drawer-content.drawer-nav .ant-menu-dark {
  background: none;
} */

.ant-drawer .ant-drawer-content.drawer-nav .ant-drawer-header {
  padding-top: 0px;
  padding-bottom: 0px;
}
.ant-drawer .ant-drawer-content.drawer-nav .ant-menu {
  background: none;
}
.App-page-content {
  /* background-color: #001529; */
  /* background-color: #282c34; */
  /* background-color: #00152D; */
  /* background-color: #1d1d1d; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 1vmin);
  /* padding: 0px 50px 12px; */
  /* color: white; */
  /* padding: 4px 16px; */
}

.App-Page-content pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}



.App-link {
  /* color: #6d5dc9;
  cursor: pointer; */
}

.App-page-header-right {
  margin: 8px 0;
}

@media only screen and (min-width: 768px) {
  .App-page-header-right {
    float: right;
    margin: 0;
    /* display: inline */
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
